import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import LazyLoad from "react-lazyload";

const Formation = () => {
  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Formation" breadcrumbName="Formation" />
      <div className="innerpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="maincontain formation">
                <div className="formation-tab">
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Intro-Formation.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                  />
                  <div>
                    <h3>
                      <i>
                        Vocations are born in Prayer...
                        <br />
                        and only in prayer can they persevere and bear fruit.
                      </i>
                    </h3>
                    <p>
                      <b>POPE FRANCIS</b>
                    </p>
                  </div>
                </div>
                <h2 className="title">Aim and Purpose of Formation</h2>
                <p>
                  Formation to be effective should define clearly our goal and
                  set before us clearly our aims and objectives, as well as how
                  to achieve our goals and aims. Every age brings its own unique
                  generation. The youth of our time have their own specific
                  ideas and outlooks, attitudes and interests, needs and
                  problems. They need suitable assistance for integrated and
                  creative growth. It is imperative that the young who enter a
                  religious congregation undergo the process of growth and
                  development in Human, Christian and Religious maturity. It
                  will help them to be ready and strong to live in any critical
                  situation. It will enable them to be confident to face new
                  challenges, to be generous, willing to search for wisdom, and
                  above all, to be secure in moving into the future without
                  hesitation and without losing their sense of direction.
                </p>
                <h3>Multi-Faced Formation</h3>
                <p>
                  The important dimensions of formation towards which our
                  formation programme is directed are:
                </p>
                <ul>
                  <li> Human Formation </li>
                  <li> Christian Formation</li>
                  <li> Religious Formation</li>
                  <li> Franciscan Formation.</li>
                </ul>
                <h3>a) Human Formation:</h3>
                <p>
                  To be fully human is to be Christ like. Human Formation is a
                  process of self- actualization in the spirit of our
                  consecration to Jesus Christ. It helps the person come to a
                  true perception of self. It deepens her self- knowledge and
                  self- acceptance, with all her strengths, potentialities and
                  weaknesses. Human Formation has practical implications for our
                  day- today life. Good manners, cleanliness, sensitivity,
                  caring and respect for authority are all areas to attend to.
                  Human Formation enables a person to express her feelings, to
                  cope with stress, to withstand moments of crisis and to face
                  challenging situations. An integrated personality depends on
                  an inner sense of security and freedom, achieved by means of
                  counseling, guidance and spiritual direction. Intellectual
                  Formation helps a person to gain the needed theoretical
                  knowledge in religious as well as in non-religious subjects.
                  It includes theological knowledge and also the professional
                  training needed to carry out apostolic mission. It demands
                  sound knowledge of the Holy Scripture, of the traditions and
                  doctrines of the Church, as well as secular and human
                  sciences. It includes proficiency in language, both English
                  and the vernacular, for clear and efficient communication with
                  others in all aspects of life.
                </p>
                <h3>b) Christian Formation</h3>
                <p>
                  The fast changing scenario in the world has influenced the
                  Christian families of today and on the whole there is a
                  decline of the Christian values specially among the young
                  generation. Due to this many of the candidates who join the
                  religious life do not have a solid faith foundation toady and
                  therefore formators have to inculcate basic Christian faith,
                  values and attitudes in the candidates. Christian vocation is
                  a gift of God and it has to be nurtured. Unless the candidates
                  deepen their faith and cultivate the Christian values they can
                  not grow in their spiritual life. Christian vocation is
                  dialectic between God’s grace and the personal response of the
                  candidates. The formator is there to facilitate the vocational
                  growth whereby the candidates learn to live a Christ-centered
                  life. For this the formator has to help the candidate to
                  deepen ones intimacy with God through prayer, meditation on
                  the word of God and sacraments. Since the Holy Spirit being
                  the Principal Agent that sows the seed of vocation and
                  nourishes it, the candidate should be taught to listen to the
                  promptings of the Spirit and to be in constant communication
                  with God. Thus the Christian Formation remains an essential
                  factor in nurturing the vocation of the candidates.
                </p>
                <h3>c) Religious Formation</h3>
                <p>
                  Religious life is essentially a life of faith. The observance
                  of the Evangelical Counsels is a concrete expression of our
                  commitment to the values of Jesus Christ. Religious formation
                  enables a person to follow Jesus in his self-emptying and to
                  be filled by Him, to become more and more a credible witness
                  to God’s kingdom. Religious formation aims at building up a
                  prayerful and apostolic company that lives and witness to its
                  ideal- Jesus Christ. The community is called to mutual love,
                  understanding, trust, respect and the spirit of
                  reconciliation. Gradually one acquires a sense of
                  co-responsibility for living the congregation’s charism and
                  mission of the congregation and shares common search for the
                  will of God in the signs of the times and in the service to
                  the needy.
                </p>
                <h3>d) Franciscan Formation</h3>
                <h4>OBJECTIVES</h4>
                <p>
                  <b>Aspirancy</b>
                </p>
                <ul>
                  <li>
                    To promote the integral Human and Christian growth of the
                    candidates.
                  </li>
                  <li>
                    To enable the candidates to have greater familiarity with
                    the Congregation.
                  </li>
                  <li>
                    To assist the candidates in the process of discernment of
                    vocation.
                  </li>
                  <li>
                    To deepen their Christian faith and love for the person of
                    Jesus.
                  </li>
                  <li>
                    To acquaint the aspirants with the culture customs and
                    traditions of each other for a harmonious living.
                  </li>
                  <li>
                    To assist them to acquire basic knowledge of Hindi and
                    English language.
                  </li>
                </ul>
                <h4>POSTULANCY</h4>
                <ul>
                  <li>
                    To promote personal growth through self-discovery, self-
                    awareness and self- acceptance.
                  </li>
                  <li>
                    To foster interpersonal relationships and emotional
                    maturity.
                  </li>
                  <li>
                    To promote an understanding and healthy attitude towards
                    sexuality.
                  </li>
                  <li>
                    To help the postulants to integrate prayer and life by
                    learning to live a life of prayer, interiority and silence,
                    deepening their taste for the word of God and their docility
                    to the Holy Spirit.
                  </li>
                  <li>
                    To encourage them to clarify their motives and deepen their
                    vocation.
                  </li>
                  <li>
                    To introduce them to the spirituality of our founder and to
                    the mission and chrism of FSLG.
                  </li>
                </ul>
                <h4>NOVITIATE - CANONICAL YEAR</h4>
                <ul>
                  <li>
                    To facilitate personal growth and transformation of the
                    novice through a process of transcendence.
                  </li>
                  <li>
                    To prepare them to be effective leaders in using their time
                    and talents for herself and for others and to develop
                    various skills.
                  </li>
                  <li>
                    To initiate the novice into a fraternal way of life – growth
                    in Christian charity, self- sacrifice, self-forgetfulness,
                    forgiving love etc.
                  </li>
                  <li>
                    To grow into a deeper relationship with Jesus shaping their
                    life according to the values of the gospel.
                  </li>
                  <li>
                    To develop a deep sense of recollectedness, interiority and
                    sensitivity to inner promptings of the Spirit and to
                    recognize the power of the Word of God to direct her life.
                  </li>
                  <li>
                    To understand the meaning of commitment through the study of
                    the Evangelical Counsels of Chastity, Poverty and Obedience
                    and through the study of the Constitution of FSLG.
                  </li>
                </ul>
                <h4>SECOND year - Novitiate</h4>
                <ul>
                  <li>
                    To enable her to grow in emotional maturity and to develop a
                    capacity for mature relationships.
                  </li>
                  <li>
                    To gain more understanding of human development in order to
                    appreciate and realize her own womanhood and to realize her
                    vocation and role as a woman.
                  </li>
                  <li>
                    To enable her to experience apostolic life and to verify the
                    authenticity of her missionary zeal, helping her to discern
                    the type of apostolate which she is inclined to witness
                    Jesus.
                  </li>
                  <li>
                    To prepare for the first commitment, helping her to reflect
                    deeply on her call in order to respond with conviction and
                    responsibility.
                  </li>
                  <li>
                    To assist her to a deeper inner preparation that she can
                    joyfully offer her life to God.
                  </li>
                  <li>
                    To enable her to become imbued with the Spirit and the
                    mission of Jesus, in order to proclaim Him to others,
                    developing a deeper awareness of the practical demands of a
                    community living and her responsibility.
                  </li>
                </ul>
                <h4>JUNIORATE</h4>
                <ul>
                  <li>
                    To grow in self- knowledge and self- acceptance, healthy
                    human relationships, leadership skills and to reflect on her
                    own responses to persons, events and situations.
                  </li>
                  <li>
                    To promote deeper understanding of religious life by a lived
                    experience in the community.
                  </li>
                  <li>
                    To help the junior sister to acquire sufficient human and
                    spiritual maturity so as to enable her to make a conscious
                    and complete offering of herself to Christ as Franciscan
                    Sisters of Our Lady of Graces.
                  </li>
                  <li>
                    To support the sister in deepening her spiritual life so as
                    to enable her to make a final choice to God and respond to
                    God’s love.
                  </li>
                  <li>
                    To recognize one’s responsibility to make the spirit and
                    charism of the congregation alive and relevant.
                  </li>
                  <li>
                    To integrate academic, apostolic and other community
                    experiences with her consecrated life.
                  </li>
                  <li>
                    To deepen Franciscan spirit and the sense of mission by
                    active involvement with people, especially the poor and
                    needy.
                  </li>
                  <li>
                    To prepare for final commitment: To enable the sister to
                    prepare for total surrender to God and His people.
                  </li>
                </ul>
                <h4>TERTIANSHIP</h4>
                <ul>
                  <li>
                    To deepen her understanding of consecrated life, vows and
                    prayer life and to integrate it with ‘mission’.
                  </li>
                  <li>
                    To enter into an intense prayer, reflection and study for
                    the final commitment to Jesus her spouse.
                  </li>
                  <li>
                    To live more fully the charism and the spirit of the
                    congregation.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 gallery_desc">
              <a
                data-magnify="gallery"
                data-src=""
                data-group="a"
                href="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-1.jpg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-1.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid galleryimg"
                  />
                </LazyLoad>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 gallery_desc">
              <a
                data-magnify="gallery"
                data-src=""
                data-group="a"
                href="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-2.jpg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-2.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid galleryimg"
                  />
                </LazyLoad>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 gallery_desc">
              <a
                data-magnify="gallery"
                data-src=""
                data-group="a"
                href="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-3.jpg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-3.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid galleryimg"
                  />
                </LazyLoad>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 gallery_desc">
              <a
                data-magnify="gallery"
                data-src=""
                data-group="a"
                href="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-4.jpg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-4.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid galleryimg"
                  />
                </LazyLoad>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 gallery_desc">
              <a
                data-magnify="gallery"
                data-src=""
                data-group="a"
                href="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-5.jpg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-5.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid galleryimg"
                  />
                </LazyLoad>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 gallery_desc">
              <a
                data-magnify="gallery"
                data-src=""
                data-group="a"
                href="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-6.jpg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-6.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid galleryimg"
                  />
                </LazyLoad>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 gallery_desc">
              <a
                data-magnify="gallery"
                data-src=""
                data-group="a"
                href="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-7.jpg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-7.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid galleryimg"
                  />
                </LazyLoad>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 gallery_desc">
              <a
                data-magnify="gallery"
                data-src=""
                data-group="a"
                href="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-8.jpg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-8.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid galleryimg"
                  />
                </LazyLoad>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 gallery_desc">
              <a
                data-magnify="gallery"
                data-src=""
                data-group="a"
                href="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-9.jpg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-9.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid galleryimg"
                  />
                </LazyLoad>
              </a>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 gallery_desc">
              <a
                data-magnify="gallery"
                data-src=""
                data-group="a"
                href="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-10.jpg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Formation-10.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid galleryimg"
                  />
                </LazyLoad>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Formation;
