// import $ from 'jquery'
import React from "react";
import Header from "../components/Header";
import Copyright from "../components/Copyright";
import SliderComponent from "../components/lightslider";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import LazyLoad from "react-lazyload";
const Home = () => {
  return (
    <>
      <Header></Header>
      <div className="container-fluid min-ht">
        <div className="row">
          <div className="col-md-12 pd-0 order-lg-12">
            <SliderComponent />
          </div>
        </div>
      </div>
      <div class="bg-image">
        <div className="about-us">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-12 col-md-12 animateMe"
                data-animation="fadeInRight">
                <div className="title">
                  <div>
                    <h3>Our Founders</h3>
                  </div>
                </div>
                <p>
                  <span>Archbishop Joseph Bartholomew</span> was born on{" "}
                  <span>April 8, 1908</span> at Casa Calistri in a village known
                  as Granaglione to Sebastiano and Assunta Evangelisti. They
                  christened him Joseph at baptism. He was the seventh of the
                  eight children – five girls and three boys. He lost his
                  beloved mother when he was seven years old. His father used to
                  take him for long walks and on one such occasion they met two
                  Capuchin Friars. Pointing to them his father asked him whether
                  he would like to be one of them. Promptly he answered ‘yes’
                  and true to his word he became one.
                </p>
                <p>
                  At an early age of thirteen, Joseph left his family to join
                  the Seraphic Seminary. He was vested in the Capuchin habit on
                  September 21, 1923 taking the name Bartholomew. After
                  finishing his Philosophical studies, he went to the Gregorian
                  University in Rome to do his Theology. His young heart was
                  overwhelmed with gratitude on July 17, 1932 when he was
                  ordained a Priest. Having obtained the Licentiate “cum laude”
                  (80%) in Dogmatic Theology, he taught at the Capuchin
                  Theological Faculty in Florence and other places.
                </p>
                <LazyLoad offset={100}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/founder-img.png"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                  />
                </LazyLoad>
                <Link to="/">
                  <div className="button">
                    <span>
                      Archbishop Joseph Bartholomew Evangelisti OFM Cap
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="history">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12 animateMe"
              data-animation="fadeInRight">
              <div className="title">
                <div>
                  <h3>
                    Our history <span>BIRTH OF OUR CONGREGATION</span>
                  </h3>
                </div>
              </div>
              <div className="history_anim">
                <img
                  src="https://webapi.entab.info/api/image/FSOLG/public/Images/history1.png"
                  className="left-img"
                  alt="Franciscan Sisters of Our Lady Of Graces"
                />
                <img
                  src="https://webapi.entab.info/api/image/FSOLG/public/Images/history2.png"
                  className="right-img"
                  alt="Franciscan Sisters of Our Lady Of Graces"
                />
                <p>
                  When Most Rev. Joseph Evangelisti, OFM Cap. was made the
                  Bishop of the newly constituted Meerut Diocese, he felt the
                  need for Women Religious in the field of evangelization. So,
                  he made requests to different Congregations to collaborate
                  with the Diocese in its pastoral and apostolic activities.
                  Though there were Congregations severing the Diocese,
                  Archbishop Evangelisti realized that he needed more persons
                  for the spreading of the Lord’s Kingdom. Then, he began to
                  think of founding a Religious Congregation for Women. He
                  sought the help of the Lord in prayer. Repeated requests from
                  Bishop Angelo Poli, OFM Cap., the retired Bishop of Allahabad,
                  to accept seven Franciscan Tertiaries was something of a sign
                  to go ahead with what was in his mind. He accepted the seven
                  Sisters in Keeping in mind the founding of a new Congregation,
                  he continued praying for light and guidance from God. After
                  much prayer and reflection, he began to work towards its
                  realization. He sought the permission from the Ecclesiastical
                  Authorities and on November 27, 1963, the Sacred Congregation
                  of Propaganda Fide granted permission to Archbishop
                  Evangelisti to erect a Religious Congregation for Women
                  through a decree bearing Protocol No. 2828/63. Thus,
                  Archbishop Evangelisti founded the Congregation of the
                  Franciscan Sisters of Our Lady of Graces on June 25, 1965. On
                  June 29 of the same year, he got the canonical deficiency of
                  the seven Sisters ratified, whom he had accepted as members of
                  the newly founded Congregation. These first members of the
                  Congregation were Sisters Angela, Rosa, Cecilia, Anna,
                  Josephine, Collette and Assunta. As said earlier, they were
                  recommended by Most Rev. Angelo Poli, as he was worried about
                  the future of these Sisters who was under his care in
                  Allahabad.
                </p>
                <Link to="/">
                  <div className="button">
                    <span>Read More</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="helm">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12 animateMe"
              data-animation="fadeInRight">
              <div className="title">
                <div>
                  <h3>
                    at the helm <span>THE ADMINISTRATION TEAM</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 ">
              <select>
                <option>2021-2026</option>
              </select>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="helm-blk">
                <LazyLoad offset={100}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/sindhu.png"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                  />
                </LazyLoad>
                <div className="title-helm">
                  <p>
                    Sr. Sindhu<span>SUPERIOR GENERAL</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="helm-blk">
                <LazyLoad offset={100}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/rajni.png"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                  />
                </LazyLoad>
                <div className="title-helm">
                  <p>
                    Sr. Rajni<span>General Counsellor</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="helm-blk">
                <LazyLoad offset={100}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/navya.png"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                  />
                </LazyLoad>
                <div className="title-helm">
                  <p>
                    Sr. Navya<span>General Councilors, General Secretary</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="helm-blk">
                <LazyLoad offset={100}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/linet.png"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                  />
                </LazyLoad>
                <div className="title-helm">
                  <p>
                    Sr. Reni<span>General Councilors</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="helm-blk">
                <LazyLoad offset={100}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/linet.png"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                  />
                </LazyLoad>
                <div className="title-helm">
                  <p>
                    Sr. Linet<span>SUPERIOR Councilors</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="helm-blk">
                <LazyLoad offset={100}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/linet.png"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                  />
                </LazyLoad>
                <div className="title-helm">
                  <p>
                    Sr. Reema<span>General Bursar</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="charism">
        <div className="container">
          <div className="row wid-50">
            <div
              className="col-lg-12 col-md-12 animateMe"
              data-animation="fadeInRight">
              <div className="title">
                <div>
                  <h3>The charism </h3>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <LazyLoad offset={100}>
                <img
                  src="https://webapi.entab.info/api/image/FSOLG/public/Images/charism-img.png"
                  className="img-fluid"
                  alt="Franciscan Sisters of Our Lady Of Graces"
                />
              </LazyLoad>
            </div>
            <div className="col-md-8">
              <p>
                Charism is to attain perfect love, experiencing personally and
                intensely the self-emptying love of the poor, humble and
                Crucified Jesus and leading a life of constant conversion and
                penance, bearing witness to the Gospel. The source and strength
                of Charism is anchored on the Gospel and is lived by
                contemplative love for God and compassionate love for the
                brethren. Through meditative life, deep prayer, and total trust
                in God, we enter into personal intimacy with the poor, humble
                and crucified Lord. It enables us for self-renunciation,
                poverty, humility, cheerfulness, simplicity and inner freedom in
                the Spirit. It holds high the ‘lowliness of heart’ as the most
                significant feature of religious life, which leads the
                individual to the option for constant conversion, penance and
                self-emptying
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Home;
