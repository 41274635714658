import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';   
import { useLocation } from 'react-router-dom';
const Header = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    const [isOpen, setIsOpen] = useState(true);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsOpen(window.innerWidth >= 991);
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    


    return (

        <div className="header ">
            {/* <div className="">
                <div className="container-fluid pd-0">
                    <div className="row">
                        <div className='col-md-2 highlight-tab'>
                           
                        </div>
                        <div className='col-md-8'>
                        
 

                        </div>
                        <div className='col-md-2 highlight-tab'>
                            <p className="d-flex space-even highlight-blk">
                                <a href="https://twitter.com/stthomasschool_?lang=en" target="_blank"><img src='https://webapi.entab.info/api/image/FSOLG/public/Images/twitter.png'></img></a>
                                <a href="https://www.instagram.com/st.thomasschool.mainpuri/" target="_blank"><img src='https://webapi.entab.info/api/image/FSOLG/public/Images/insta.png'></img></a>
                                <a href="https://www.youtube.com/watch?v=6-sKinVruzI" target="_blank"><img src='https://webapi.entab.info/api/image/FSOLG/public/Images/facebook.png'></img></a>
                                <a href="https://www.youtube.com/watch?v=6-sKinVruzI" target="_blank"><img src='https://webapi.entab.info/api/image/FSOLG/public/Images/linkdin.png'></img></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="header-blk">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xl-4 col-lg-12'>
                            <Link to="/">
                                <div className='logo'>
                                    <img src="https://webapi.entab.info/api/image/FSOLG/public/Images/logo.png" className='img-fluid' alt="Franciscan Sisters of Our Lady Of Graces"></img>
                                </div>
                            </Link>
                        </div>
                        <div className='col-xl-8 col-lg-12'>
                            <div className="menu">

                                {isOpen && (
                                    <div>
                                        {/* <div class="logo">
                                            <NavLink to="/">Home</NavLink>
                                        </div> */}
                                        <ul className="submenu  animate__animated animate__fadeIn animate__delay-1s">

                                            <li>about
                                                <ul className="sub-submenu">
                                                    <li><NavLink to='/founder'>Our Founder</NavLink></li>
                                                    <li><NavLink to='/administration'>Administration</NavLink></li>
                                                    <li><NavLink to='/contact'>Contact</NavLink></li>
                                                </ul>
                                            </li>

                                            <li>
                                            <NavLink to="/history">history</NavLink>
                                                {/* <ul className="sub-submenu">
                                                    <li><NavLink to='/'>Syllabus</NavLink></li>
                                                    <li><NavLink to='/'>School Timings</NavLink></li>
                                                </ul> */}
                                            </li>

                                            <li>
                                            <NavLink to="/formation">formation</NavLink>

                                                {/* <ul className="sub-submenu">
                                                    <li><NavLink to='/'>Annual Fee Structure</NavLink></li>
                                                    <li><NavLink to='/'>Admission Procedure</NavLink></li>
                                                    <li><NavLink to='/'>Admission Information</NavLink></li>
                                                </ul> */}
                                            </li>
                                            <li>
                                            apostolates
                                                <ul className="sub-submenu">
                                                    <li><NavLink to='/'> Spiritual Commission </NavLink></li>
                                                    <li><NavLink to='/'>Vocation Commission</NavLink></li>
                                                    <li><NavLink to='/'>Formation </NavLink></li>
                                                    <li><NavLink to='/education-ministry'>Education Ministry </NavLink></li>
                                                    <li><NavLink to='/healthministry'>Health & Healing Ministry</NavLink></li>
                                                    <li><NavLink to='/socialministry'>Social Ministry </NavLink></li>
                                                    <li><NavLink to='/pastoralministry'>Pastoral Ministry  </NavLink></li>
                                                    <li><NavLink to='/'>Finance  </NavLink></li>
                                                    <li><NavLink to='/'>Infrastructure & Property  </NavLink></li>
                                                    <li><NavLink to='/'>Media & Commission </NavLink></li>
                                                    <li><NavLink to='/'>Legal Aid  </NavLink></li>
                                                    <li><NavLink to='/'>Disaster Management  </NavLink></li>
                                                </ul>
                                            </li>
                                            <li>
                                            communities
                                                <ul className="sub-submenu">

                                                    <li><NavLink to='/'>Generalate</NavLink></li>
                                                    <li><NavLink to='/kripaDhara'>Kripa Dhara</NavLink></li>
                                                    <li><NavLink to='/shantiDhara'>Shanti Dhara</NavLink></li>
                                                </ul>
                                            </li>
                                            <li>
                                            Regions
                                               
                                            </li>
                                            <li>
                                            <NavLink to="/flowerInHeaven">flowers in heaven</NavLink>
                                                {/* <ul className="sub-submenu">
                                                    <li><NavLink to='/'>Photo Gallery</NavLink></li>
                                                    <li><NavLink to='/'>Video Gallery</NavLink></li>
                                                </ul> */}
                                            </li>
                                            <li>
                                            gallery
                                                <ul className="sub-submenu">
                                                    <li><NavLink to='/gallery'>Photo Gallery</NavLink></li>
                                                    <li><NavLink to='/videoGallery'>Video Gallery</NavLink></li>
                                                    <li><NavLink to='/'>Media Gallery</NavLink></li>
                                                </ul>
                                            </li>
                                            {/* <li>
                                                contact
                                                <ul className="sub-submenu">
                                                    <li><NavLink to='/'>contact us</NavLink></li>
                                                </ul>
                                            </li> */}

                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='menu-tab'>
                            <button className="menu-toggle" onClick={toggleMenu}><i class="bi bi-list"></i></button>
                        </div>


                    </div>
                </div>
            </div>


        </div>
    );
};

export default Header;