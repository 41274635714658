import React from 'react'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Events = () => {
    var settings = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        autoplay: true,
        nav: true,
        dots: false, smartSpeed: 1500, // transition speed
        fluidSpeed: 2000, 
        items: 1,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
            1600: {
                items: 1,
            }
        }
    };

    return (
        <OwlCarousel className='owl-theme main-slider' loop margin={10} {...settings}>
            {/* <div className="item">
              <img src='https://webapi.entab.info/api/image/FSOLG/public/Images/sld2.jpg' />
              <div class="slide-content">
                <h2>
                Tagore Public School <br/>opening soon -<br /> <span>learn and grow </span> with us! <span></span> 
                </h2>
              </div>
            </div> */}
            <div className="item">
              <img src='https://webapi.entab.info/api/image/FSOLG/public/Images/sld-5.jpg' alt="Franciscan Sisters of Our Lady Of Graces" />              
            </div>  
            <div className="item">
              <img src='https://webapi.entab.info/api/image/FSOLG/public/Images/sld-9.jpg' alt="Franciscan Sisters of Our Lady Of Graces"  />              
            </div>  
            <div className="item">
              <img src='https://webapi.entab.info/api/image/FSOLG/public/Images/sld-6.jpg' alt="Franciscan Sisters of Our Lady Of Graces"  />              
            </div>            
            <div className="item">
              <img src='https://webapi.entab.info/api/image/FSOLG/public/Images/sld-7.jpg' alt="Franciscan Sisters of Our Lady Of Graces"  />              
            </div>           
            <div className="item">
              <img src='https://webapi.entab.info/api/image/FSOLG/public/Images/sld-8.jpg' alt="Franciscan Sisters of Our Lady Of Graces"  />              
            </div>           
            <div className="item">
              <img src='https://webapi.entab.info/api/image/FSOLG/public/Images/sld-4.jpg' alt="Franciscan Sisters of Our Lady Of Graces"  />              
            </div>  
            <div className="item">
              <img src='https://webapi.entab.info/api/image/FSOLG/public/Images/sld-3.jpg' alt="Franciscan Sisters of Our Lady Of Graces" />              
            </div>  
            <div className="item">
              <img src='https://webapi.entab.info/api/image/FSOLG/public/Images/sld-2.jpg' alt="Franciscan Sisters of Our Lady Of Graces"  />              
            </div>  
            <div className="item">
              <img src='https://webapi.entab.info/api/image/FSOLG/public/Images/sld-1.jpg' alt="Franciscan Sisters of Our Lady Of Graces"  />              
            </div>          
        </OwlCarousel>
    );
};

export default Events;